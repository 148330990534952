<template>
  <div class="ele-body">
    <a-card :bordered="false">
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-form-item
          label="标题:"
          :labelCol="{ span: 2, offset: 0 }"
          name="articleName"
        >
          <a-input
            v-model:value="form.articleTitle"
            placeholder="请输入标题"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          label="发布时间"
          :labelCol="{ span: 2, offset: 0 }"
          name="createTime"
        >
          <a-date-picker
            class="ud-fluid"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="请输入发布时间"
            v-model:value="form.createTime"
            show-time
          />
        </a-form-item>
        <a-form-item
          label="内容"
          :labelCol="{ span: 2, offset: 0 }"
          name="articleContent"
        >
          <tinymce-editor v-model:value="content" :init="{ height: 525 }" />
        </a-form-item>
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item
              label="排序:"
              :labelCol="{ span: 8, offset: 0 }"
              name="sortNumber"
            >
              <a-input-number
                v-model:value="form.sortNumber"
                :min="0"
                :step="1"
              />
              <span class="span">数字越小越靠前</span>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="是否显示:" name="articleStatus">
              <a-select
                v-model:value="form.articleStatus"
                :options="selectOption"
                @change="selectChange"
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          label=" "
          :colon="false"
          :labelCol="{ span: 2, offset: 0 }"
          name="virtualViews"
        >
          <a-button type="primary" @click="save"> 提交 </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { Modal } from 'ant-design-vue'
import * as agreementApi from '@/api/rnt/agreement'
export default {
  name: 'policyEdit',
  components: {
    TinymceEditor
  },
  data() {
    return {
      // 是否显示
      selectOption: [
        {
          value: 0,
          label: '不显示'
        },
        {
          value: 1,
          label: '显示'
        }
      ],
      value: '',
      form: {},
      rules: [],
      fileList: [],
      loading2: false,
      imageUrl: '',
      isUpdate: false,
      content: '',
      onshow: true
    }
  },
  created() {
    this.queryArticle()
  },
  watch: {
    $route(to, from) {
      if (to.name === '/zulin/agreement/detail') {
        this.onshow = true
        this.content = this.form.articleContent
        if (this.$route.query.id) {
          this.queryArticle()
          this.isUpdate = true
        } else {
          this.form = {}
          this.content = ''
          this.isUpdate = false
        }
      } else {
        if (this.onshow) {
          this.onshow = false
          this.form.articleContent = this.content
          this.content = ''
        }
      }
    }
  },
  methods: {
    /* 上传 */
    doUpload({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post('/file/upload', formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.imageUrl = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          this.form.imagePath = this.imageUrl
          this.form.articleContent = this.content
          agreementApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.form.articleContent = res.data
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 获取编辑器纯文本内容 */
    showText() {
      Modal.info({
        maskClosable: true,
        content: this.$util.htmlToText(this.value)
      })
    },
    queryArticle() {
      const query = this.$route.query
      if (query.id > 0) {
        const that = this
        that.isUpdate = true
        const hide = this.$message.loading('请求中..', 0)
        agreementApi
          .getById(query.id)
          .then((res) => {
            hide()
            if (res.code === 0) {
              that.form = Object.assign({}, this.data, res.data)
              that.content = that.form.articleContent
              that.imageUrl = res.data.imagePath
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            hide()
            this.$message.error(e.message)
          })
      }
    },
    // 是否显示
    selectChange(value) {
      this.form.articleStatus = value
    }
  }
}
</script>

<style scoped>
.span {
  color: grey;
  padding-left: 10px;
}
</style>
